<template class="guide_dialog">
  <el-dialog
    :visible.sync="isDialogShow"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    :modal="false"
    width="75%"
    center
  >
    <div slot="title">
      <h1 class="header-title">
        兌換資訊
      </h1>
    </div>
    <el-card :body-style="{ padding: '0px' }">
      <div class="text item body-text">
        收貨人姓名: {{ form.name }}
      </div>
      <div class="text item body-text">
        收貨地址：{{ form.address }}
      </div>
      <div class="text item body-text">
        手機: {{ form.phone }}
      </div>
      <div class="text item body-text">
        市話: {{ form.tel }}
      </div>
      <div class="text item body-text">
        預計收貨日期: {{ form.startTime.time }}
      </div>
    </el-card>
    <div class="btnBox">
      <button
        class="btn_1"
        @click="close"
      >
        <span>關閉</span>
      </button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'TheQuerV2ShowInfo',
  props: {
    isDialogShow: { type: Boolean, default: false },
    form: {
      type: Object,
      default: function () {
        return {
          name: '',
          address: '',
          phone: '',
          tel: '',
          startTime: {
            time: ''
          }
        }
      }
    }
  },
  data () {
    return {}
  },
  methods: {
    close () {
      this.$emit('closeQuerV2ShowInfo')
    }
  }
}
</script>

<style scoped lang="scss">
.btnBox {
  display: flex;
  width: 100%;
  justify-content: center;
  .btn_1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(50% - 10px);
    margin: 0 5px;
    height: 4.6rem;
    background-color: #c8161d;
    border-radius: 0.6rem;
    font-size: 1.4rem;
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
    border: 0;
  }
  .btn_1[disabled] {
    background-color: #c7c7c7;
  }
  .btn_2 {
    margin-top: 1.2rem;
    cursor: pointer;
  }
}
.header-title {
  margin-top: 1.5rem;
  line-height: 3rem;
  font-size: 2.2rem;
  color: rgba(61, 61, 61, 1);
  font-weight: 700;
}
.body-text {
  background-color: #e0f6fd;
  padding: 10px;
  border-radius: 10px;
  // color: #868686;
  width: 100%;
  margin: 5px 0 15px 0;
  line-height: 22px;
  font-size: 14px;
}
</style>
