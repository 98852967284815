export const tmpExchangeMap = {
  1: {
    enable: true,
    name: 'P0230-F0161 複合式濾心',
    point: 1590,
    info: `提供P0230 3-6個月所需的濾心，維持家中淨水器的飲用水水質健康。
  ※「P0230 3-6個月濾心」內容物包含：
  F0161 複合式濾心，成份：聚丙烯+活性炭纖維，共一支。`,
    notice: `1.本項目在確認兌換後，將扣除所需點數1,590點，兌換成功時，淨水管家即會通知服務專員於10日內與您聯繫。
  2.本項目於兌換後不可任意更換兌換內容物。
  3.本項目於點數扣除後無法復原。
  4.本項目僅適用於淨水管家使用，無法於實體通路進行兌換。
  5.相關櫻花點數商城辦法請至淨水管家的會員權益須知。
  6.兌換濾心不得轉售。`
  },
  2: {
    enable: true,
    name: 'P0230-F0181 RO膜濾心(400aG)',
    point: 4990,
    info: `提供P0230 18-24個月所需的濾心，維持家中淨水器的飲用水水質健康。
  ※「P0230 18-24個月濾心」內容物包含：
  F0181 RO 膜濾心 400G (NSF認證)，成份：逆滲透薄膜，共一支。`,
    notice: `1.本項目在確認兌換後，將扣除所需點數4,990點，兌換成功時，淨水管家即會通知服務專員於10日內與您聯繫。
  2.本項目於兌換後不可任意更換兌換內容物。
  3.本項目於點數扣除後無法復原。
  4.本項目僅適用於淨水管家使用，無法於實體通路進行兌換。
  5.相關櫻花點數商城辦法請至淨水管家的會員權益須知。
  6.兌換濾心不得轉售。`
  },
  3: {
    enable: true,
    name: 'P0230-F0151 後置活性碳濾心',
    point: 1690,
    info: `提供P0230 6-12個月所需的濾心，維持家中淨水器的飲用水水質健康。
  ※「P0230 6-12個月濾心」內容物包含：
  F0151 後置活性碳濾心，成份：椰殼顆粒活性碳，共一支。`,
    notice: `1.本項目在確認兌換後，將扣除所需點數1,690點，兌換成功時，淨水管家即會通知服務專員於10日內與您聯繫。
  2.本項目於兌換後不可任意更換兌換內容物。
  3.本項目於點數扣除後無法復原。
  4.本項目僅適用於淨水管家使用，無法於實體通路進行兌換。
  5.相關櫻花點數商城辦法請至淨水管家的會員權益須知。
  6.兌換濾心不得轉售。`
  },
  4: {
    enable: true,
    name: 'P0231-F0161 複合式濾心',
    point: 1590,
    info: `提供P0231 3-6個月所需的濾心，維持家中淨水器的飲用水水質健康。
  ※「P0231 3-6個月濾心」內容物包含：
  F0161 複合式濾心，成份：聚丙烯+活性炭纖維，共一支。`,
    notice: `1.本項目在確認兌換後，將扣除所需點數1,590點，兌換成功時，淨水管家即會通知服務專員於10日內與您聯繫。
  2.本項目於兌換後不可任意更換兌換內容物。
  3.本項目於點數扣除後無法復原。
  4.本項目僅適用於淨水管家使用，無法於實體通路進行兌換。
  5.相關櫻花點數商城辦法請至淨水管家的會員權益須知。
  6.兌換濾心不得轉售。`
  },
  5: {
    enable: true,
    name: 'P0231-F0182 RO膜濾心(600G)',
    point: 5990,
    info: `提供P0231 所需的RO膜濾心(600G)，維持家中淨水器的飲用水水質健康。
  ※「P0231 RO膜濾心(600G)」內容物包含：
  F0181 RO 膜濾心 600G (NSF認證)，成份：逆滲透薄膜，共一支。`,
    notice: `1.本項目在確認兌換後，將扣除所需點數5,990點，兌換成功時，淨水管家即會通知服務專員於10日內與您聯繫。
  2.本項目於兌換後不可任意更換兌換內容物。
  3.本項目於點數扣除後無法復原。
  4.本項目僅適用於淨水管家使用，無法於實體通路進行兌換。
  5.相關櫻花點數商城辦法請至淨水管家的會員權益須知。
  6.兌換濾心不得轉售。`
  },
  6: {
    enable: true,
    name: 'P0231-F0151 後置活性碳濾心',
    point: 1690,
    info: `提供P0231 所需的後置活性碳濾心，維持家中淨水器的飲用水水質健康。
  ※「P0231 後置活性碳濾心」內容物包含：
  F0151 後置活性碳濾心，成份：椰殼顆粒活性碳，共一支。`,
    notice: `1.本項目在確認兌換後，將扣除所需點數1,690點，兌換成功時，淨水管家即會通知服務專員於10日內與您聯繫。
  2.本項目於兌換後不可任意更換兌換內容物。
  3.本項目於點數扣除後無法復原。
  4.本項目僅適用於淨水管家使用，無法於實體通路進行兌換。
  5.相關櫻花點數商城辦法請至淨水管家的會員權益須知。
  6.兌換濾心不得轉售。`
  },
  7: {
    enable: true,
    name: 'P0233-F0162 雙效複合式濾心',
    point: 2990,
    info: `提供P0233 所需的雙效複合式濾心，維持家中淨水器的飲用水水質健康。
   ※「P0233 雙效複合式濾心」內容物包含：
   F0162 雙效複合式濾心，成份：聚丙烯+活性碳纖維+活性炭棒，共一支。`,
    notice: `1.本項目在確認兌換後，將扣除所需點數2,990點，兌換成功時，淨水管家即會通知服務專員於10日內與您聯繫。
   2.本項目於兌換後不可任意更換兌換內容物。
   3.本項目於點數扣除後無法復原。
   4.本項目僅適用於淨水管家使用，無法於實體通路進行兌換。
   5.相關櫻花點數商城辦法請至淨水管家的會員權益須知。
   6.兌換濾心不得轉售。`
  },
  8: {
    enable: true,
    name: 'P0233-F0185 RO膜濾心(400G)',
    point: 4990,
    info: `提供P0233 所需的RO膜濾心(400G)，維持家中淨水器的飲用水水質健康。
   ※「P0233 RO膜濾心(400G)」內容物包含：
   F0185 RO膜濾心 400G (NSF認證)，成份：逆滲透薄膜，共一支。`,
    notice: `1.本項目在確認兌換後，將扣除所需點數4,990點，兌換成功時，淨水管家即會通知服務專員於10日內與您聯繫。
   2.本項目於兌換後不可任意更換兌換內容物。
   3.本項目於點數扣除後無法復原。
   4.本項目僅適用於淨水管家使用，無法於實體通路進行兌換。
   5.相關櫻花點數商城辦法請至淨水管家的會員權益須知。
   6.兌換濾心不得轉售。`
  },
  9: {
    enable: true,
    name: 'P0121-F0110 PP濾心',
    point: 490,
    info: `提供P0121 所需的PP濾心，維持家中淨水器的飲用水水質健康。
   ※「P0121 PP濾心」內容物包含：
   F0110 PP濾心，成份：聚丙烯，共一支。`,
    notice: `1.本項目在確認兌換後，將扣除所需點數490點，兌換成功時，淨水管家即會通知服務專員於10日內與您聯繫。
   2.本項目於兌換後不可任意更換兌換內容物。
   3.本項目於點數扣除後無法復原。
   4.本項目僅適用於淨水管家使用，無法於實體通路進行兌換。
   5.相關櫻花點數商城辦法請至淨水管家的會員權益須知。
   6.兌換濾心不得轉售。`
  },
  10: {
    enable: true,
    name: 'P0121-F0130 CB濾心',
    point: 890,
    info: `提供P0121所需的CB濾心，維持家中淨水器的飲用水水質健康。
  ※「P0121 CB濾心」內容物包含：
  F0130 活性碳濾心，成份：活性碳棒，共一支。`,
    notice: `1.本項目在確認兌換後，將扣除所需點數890點，兌換成功時，淨水管家即會通知服務專員於10日內與您聯繫。
  2.本項目於兌換後不可任意更換兌換內容物。
  3.本項目於點數扣除後無法復原。
  4.本項目僅適用於淨水管家使用，無法於實體通路進行兌換。
  5.相關櫻花點數商城辦法請至淨水管家的會員權益須知。
  6.兌換濾心不得轉售。`
  },
  11: {
    enable: true,
    name: 'P0121-F0180 RO膜濾心(50G)',
    point: 2290,
    info: `提供P0121 所需的RO膜濾心(50G)，維持家中淨水器的飲用水水質健康。
   ※「P0121 RO膜濾心(50G)」內容物包含：
   F0180 RO 膜濾心 50G (NSF認證)，成份：逆滲透薄膜，共一支。`,
    notice: `1.本項目在確認兌換後，將扣除所需點數2,290點，兌換成功時，淨水管家即會通知服務專員於10日內與您聯繫。
   2.本項目於兌換後不可任意更換兌換內容物。
   3.本項目於點數扣除後無法復原。
   4.本項目僅適用於淨水管家使用，無法於實體通路進行兌換。
   5.相關櫻花點數商城辦法請至淨水管家的會員權益須知。
   6.兌換濾心不得轉售。`
  },
  12: {
    enable: true,
    name: 'P0121-F0150 GAC濾心',
    point: 1690,
    info: `提供P0121 所需的GAC濾心，維持家中淨水器的飲用水水質健康。
   ※「P0121 GAC濾心」內容物包含：
   F0150 後置活性碳濾心，成份：椰殼顆粒活性碳，共一支。`,
    notice: `1.本項目在確認兌換後，將扣除所需點數1,690點，兌換成功時，淨水管家即會通知服務專員於10日內與您聯繫。
   2.本項目於兌換後不可任意更換兌換內容物。
   3.本項目於點數扣除後無法復原。
   4.本項目僅適用於淨水管家使用，無法於實體通路進行兌換。
   5.相關櫻花點數商城辦法請至淨水管家的會員權益須知。
   6.兌換濾心不得轉售。`
  },
  13: {
    enable: true,
    name: 'P0670-活性碳纖維濾心',
    point: 2690,
    info: `提供P0670 所需的活性碳纖維濾心，維持家中淨水器的飲用水水質健康。
   ※「P0670 活性碳纖維濾心」內容物包含：
   F0231 活性碳纖維濾心 (NSF認證)，成份：活性碳纖維，共一支。`,
    notice: `1.本項目在確認兌換後，將扣除所需點數2,690點，兌換成功時，淨水管家即會通知服務專員於10日內與您聯繫。
   2.本項目於兌換後不可任意更換兌換內容物。
   3.本項目於點數扣除後無法復原。
   4.本項目僅適用於淨水管家使用，無法於實體通路進行兌換。
   5.相關櫻花點數商城辦法請至淨水管家的會員權益須知。
   6.兌換濾心不得轉售。`
  },
  14: {
    enable: true,
    name: 'P0680-F0211 PP濾心',
    point: 490,
    info: `提供P0680 所需的PP濾心，維持家中淨水器的飲用水水質健康。
   ※「P0680 PP濾心」內容物包含：
   F0211 PP濾心，成份：聚丙烯，共一支。`,
    notice: `1.本項目在確認兌換後，將扣除所需點數490點，兌換成功時，淨水管家即會通知服務專員於10日內與您聯繫。
   2.本項目於兌換後不可任意更換兌換內容物。
   3.本項目於點數扣除後無法復原。
   4.本項目僅適用於淨水管家使用，無法於實體通路進行兌換。
   5.相關櫻花點數商城辦法請至淨水管家的會員權益須知。
   6.兌換濾心不得轉售。`
  },
  15: {
    enable: true,
    name: 'P0680-F0231 活性碳纖維濾心',
    point: 2690,
    info: `提供P0680 所需的活性碳纖維濾心，維持家中淨水器的飲用水水質健康。
   ※「P0680 活性碳纖維濾心」內容物包含：
   F0231 活性碳纖維濾心 (NSF認證)，成份：活性碳纖維，共一支。`,
    notice: `1.本項目在確認兌換後，將扣除所需點數2,690點，兌換成功時，淨水管家即會通知服務專員於10日內與您聯繫。
   2.本項目於兌換後不可任意更換兌換內容物。
   3.本項目於點數扣除後無法復原。
   4.本項目僅適用於淨水管家使用，無法於實體通路進行兌換。
   5.相關櫻花點數商城辦法請至淨水管家的會員權益須知。
   6.兌換濾心不得轉售。`
  },
  16: {
    enable: true,
    name: 'P0681-F0221 前置樹脂濾心',
    point: 790,
    info: `提供P0681 所需的前置樹脂濾心，維持家中淨水器的飲用水水質健康。
   ※「P0681 前置樹脂濾心」內容物包含：
   F0221 前置樹脂濾心，成份：無鈉型離子交換樹脂，共一支。`,
    notice: `1.本項目在確認兌換後，將扣除所需點數790點，兌換成功時，淨水管家即會通知服務專員於10日內與您聯繫。
   2.本項目於兌換後不可任意更換兌換內容物。
   3.本項目於點數扣除後無法復原。
   4.本項目僅適用於淨水管家使用，無法於實體通路進行兌換。
   5.相關櫻花點數商城辦法請至淨水管家的會員權益須知。
   6.兌換濾心不得轉售。`
  },
  17: {
    enable: true,
    name: 'P0681-F0231 活性碳纖維濾心',
    point: 2690,
    info: `提供P0681 所需的活性碳纖維濾心，維持家中淨水器的飲用水水質健康。
  ※「P0681 活性碳纖維濾心」內容物包含：
  F0231 活性碳纖維濾心 (NSF認證)，成份：活性碳纖維，共一支。`,
    notice: `1.本項目在確認兌換後，將扣除所需點數2,690點，兌換成功時，淨水管家即會通知服務專員於10日內與您聯繫。
   2.本項目於兌換後不可任意更換兌換內容物。
   3.本項目於點數扣除後無法復原。
   4.本項目僅適用於淨水管家使用，無法於實體通路進行兌換。
   5.相關櫻花點數商城辦法請至淨水管家的會員權益須知。
   6.兌換濾心不得轉售。`
  },
  18: {
    enable: true,
    name: 'P0780-F0271 AF濾心',
    point: 1590,
    info: `提供P0780 所需的AF濾心，維持家中淨水器的飲用水水質健康。
   ※「P0780 AF濾心」內容物包含：
   F0271 AF濾心，成份：礦物纖維，共一支。`,
    notice: `1.本項目在確認兌換後，將扣除所需點數1,590點，兌換成功時，淨水管家即會通知服務專員於10日內與您聯繫。
   2.本項目於兌換後不可任意更換兌換內容物。
   3.本項目於點數扣除後無法復原。
   4.本項目僅適用於淨水管家使用，無法於實體通路進行兌換。
   5.相關櫻花點數商城辦法請至淨水管家的會員權益須知。
   6.兌換濾心不得轉售。`
  },
  19: {
    enable: true,
    name: 'P0780-F0231 活性碳纖維濾心',
    point: 2690,
    info: `提供P0780 所需的活性碳纖維濾心，維持家中淨水器的飲用水水質健康。
  ※「P0780 活性碳纖維濾心」內容物包含：
  F0231 活性碳纖維濾心 (NSF認證)，成份：活性碳纖維，共一支。`,
    notice: `1.本項目在確認兌換後，將扣除所需點數5,990點，兌換成功時，淨水管家即會通知服務專員於10日內與您聯繫。
   2.本項目於兌換後不可任意更換兌換內容物。
   3.本項目於點數扣除後無法復原。
   4.本項目僅適用於淨水管家使用，無法於實體通路進行兌換。
   5.相關櫻花點數商城辦法請至淨水管家的會員權益須知。
   6.兌換濾心不得轉售。`
  },
  20: {
    enable: true,
    name: 'P0782-F0271 AF濾心',
    point: 1590,
    info: `提供P0782 所需的AF濾心，維持家中淨水器的飲用水水質健康。
   ※「P0782AF濾心」內容物包含：
   F0271 AF濾心，成份：礦物纖維，共一支。`,
    notice: `1.本項目在確認兌換後，將扣除所需點數1,590點，兌換成功時，淨水管家即會通知服務專員於10日內與您聯繫。
   2.本項目於兌換後不可任意更換兌換內容物。
   3.本項目於點數扣除後無法復原。
   4.本項目僅適用於淨水管家使用，無法於實體通路進行兌換。
   5.相關櫻花點數商城辦法請至淨水管家的會員權益須知。
   6.兌換濾心不得轉售。`
  },
  21: {
    enable: true,
    name: 'P0782-F0232 除鉛活性碳纖維濾心',
    point: 2690,
    info: `提供P0782 所需的除鉛活性碳纖維濾心，維持家中淨水器的飲用水水質健康。
   ※「P0782 除鉛活性碳纖維濾心」內容物包含：
   F0232 除鉛活性碳纖維濾心，成份：除鉛活性碳纖維，共一支。`,
    notice: `1.本項目在確認兌換後，將扣除所需點數2,690點，兌換成功時，淨水管家即會通知服務專員於10日內與您聯繫。
   2.本項目於兌換後不可任意更換兌換內容物。
   3.本項目於點數扣除後無法復原。
   4.本項目僅適用於淨水管家使用，無法於實體通路進行兌換。
   5.相關櫻花點數商城辦法請至淨水管家的會員權益須知。
   6.兌換濾心不得轉售。`
  },
  22: {
    enable: true,
    name: 'P0235-F0162 雙效複合式濾心',
    point: 2990,
    info: `提供P0235 所需的雙效複合式濾心，維持家中淨水器的飲用水水質健康。
  ※「P0235  雙效複合式濾心」內容物包含：
  F0162 雙效複合式濾心，成份：聚丙烯+活性碳纖維+活性炭棒，共一支。`,
    notice: `1.本項目在確認兌換後，將扣除所需點數2,990點，兌換成功時，淨水管家即會通知服務專員於10日內與您聯繫。
  2.本項目於兌換後不可任意更換兌換內容物。
  3.本項目於點數扣除後無法復原。
  4.本項目僅適用於淨水管家使用，無法於實體通路進行兌換。
  5.相關櫻花點數商城辦法請至淨水管家的會員權益須知。
  6.兌換濾心不得轉售。`
  },
  23: {
    enable: true,
    name: 'P0235-F0186 RO膜濾心(600G)',
    point: 5990,
    info: `提供P0235 所需的RO膜濾心(600G)，維持家中淨水器的飲用水水質健康。
   ※「P0235 RO膜濾心(600G)」內容物包含：
   F0186 RO膜濾心 600G (NSF認證)，成份：逆滲透薄膜，共一支。`,
    notice: `1.本項目在確認兌換後，將扣除所需點數5,990點，兌換成功時，淨水管家即會通知服務專員於10日內與您聯繫。
   2.本項目於兌換後不可任意更換兌換內容物。
   3.本項目於點數扣除後無法復原。
   4.本項目僅適用於淨水管家使用，無法於實體通路進行兌換。
   5.相關櫻花點數商城辦法請至淨水管家的會員權益須知。
   6.兌換濾心不得轉售。`
  },
  24: {
    enable: false,
    name: 'XP0232-F0161 複合式濾心',
    point: 1590,
    info: `提供XP0232 所需的複合式濾心，維持家中淨水器的飲用水水質健康。
   ※「XP0232 複合式濾心」內容物包含：
   F0161 複合式濾心，成份：聚丙烯+活性炭纖維，共一支。`,
    notice: `1.本項目在確認兌換後，將扣除所需點數1,590點，兌換成功時，淨水管家即會通知服務專員於10日內與您聯繫。
   2.本項目於兌換後不可任意更換兌換內容物。
   3.本項目於點數扣除後無法復原。
   4.本項目僅適用於淨水管家使用，無法於實體通路進行兌換。
   5.相關櫻花點數商城辦法請至淨水管家的會員權益須知。
   6.兌換濾心不得轉售。`
  },
  25: {
    enable: false,
    name: 'XP0232-F0183 RO膜濾心(800G)',
    point: 6990,
    info: `提供XP0232 所需的RO膜濾心(800G)，維持家中淨水器的飲用水水質健康。
  ※「XP0232  RO膜濾心(800G)」內容物包含：
  F0183 RO膜濾心 800G (NSF認證)，成份：逆滲透薄膜，共一支。`,
    notice: `1.本項目在確認兌換後，將扣除所需點數6,990點，兌換成功時，淨水管家即會通知服務專員於10日內與您聯繫。
   2.本項目於兌換後不可任意更換兌換內容物。
   3.本項目於點數扣除後無法復原。
   4.本項目僅適用於淨水管家使用，無法於實體通路進行兌換。
   5.相關櫻花點數商城辦法請至淨水管家的會員權益須知。
   6.兌換濾心不得轉售。`
  },
  26: {
    enable: false,
    name: 'XP0232-F0151 後置活性碳濾心',
    point: 1690,
    info: `提供XP0232 所需的後置活性碳濾心，維持家中淨水器的飲用水水質健康。
     ※「XP0232 後置活性碳濾心」內容物包含：
     F0151 後置活性碳濾心，成份：椰殼顆粒活性碳，共一支。`,
    notice: `1.本項目在確認兌換後，將扣除所需點數1,690點，兌換成功時，淨水管家即會通知服務專員於10日內與您聯繫。
     2.本項目於兌換後不可任意更換兌換內容物。
     3.本項目於點數扣除後無法復原。
     4.本項目僅適用於淨水管家使用，無法於實體通路進行兌換。
     5.相關櫻花點數商城辦法請至淨水管家的會員權益須知。
     6.兌換濾心不得轉售。`
  },
  27: {
    enable: false,
    name: 'XP0781-F0271 AF濾心',
    point: 1590,
    info: `提供XP0781 所需的AF濾心，維持家中淨水器的飲用水水質健康。
     ※「XP0781 AF濾心」內容物包含：
     F0271 AF濾心，成份：礦物纖維，共一支。`,
    notice: `1.本項目在確認兌換後，將扣除所需點數1,590點，兌換成功時，淨水管家即會通知服務專員於10日內與您聯繫。
     2.本項目於兌換後不可任意更換兌換內容物。
     3.本項目於點數扣除後無法復原。
     4.本項目僅適用於淨水管家使用，無法於實體通路進行兌換。
     5.相關櫻花點數商城辦法請至淨水管家的會員權益須知。
     6.兌換濾心不得轉售。`
  },
  28: {
    enable: false,
    name: 'XP0781-F0231 活性碳纖維濾心',
    point: 2690,
    info: `提供XP0781 所需的活性碳纖維濾心，維持家中淨水器的飲用水水質健康。
    ※「XP0781 活性碳纖維濾心」內容物包含：
    F0231 活性碳纖維濾心 (NSF認證)，成份：活性碳纖維，共一支。`,
    notice: `1.本項目在確認兌換後，將扣除所需點數2,690點，兌換成功時，淨水管家即會通知服務專員於10日內與您聯繫。
     2.本項目於兌換後不可任意更換兌換內容物。
     3.本項目於點數扣除後無法復原。
     4.本項目僅適用於淨水管家使用，無法於實體通路進行兌換。
     5.相關櫻花點數商城辦法請至淨水管家的會員權益須知。
     6.兌換濾心不得轉售。`
  },
  29: {
    enable: true,
    name: 'P0583-F0271 AF濾心',
    point: 1590,
    info: `提供P0583 所需的AF濾心，維持家中淨水器的飲用水水質健康。
    ※「P0583 AF濾心」內容物包含：
    F0271 AF濾心，成份：礦物纖維，共一支。`,
    notice: `1.本項目在確認兌換後，將扣除所需點數1,590點，兌換成功時，淨水管家即會通知服務專員於10日內與您聯繫。
    2.本項目於兌換後不可任意更換兌換內容物。
    3.本項目於點數扣除後無法復原。
    4.本項目僅適用於淨水管家使用，無法於實體通路進行兌換。
    5.相關櫻花點數商城辦法請至淨水管家的會員權益須知。
    6.兌換濾心不得轉售。`
  },
  30: {
    enable: true,
    name: 'P0583-F0231 活性碳纖維濾心',
    point: 2690,
    info: `提供P0583 所需的活性碳纖維濾心，維持家中淨水器的飲用水水質健康。
    ※「P0583 活性碳纖維濾心」內容物包含：
    F0231 活性碳纖維濾心 (NSF認證)，成份：活性碳纖維，共一支。`,
    notice: `1.本項目在確認兌換後，將扣除所需點數2,690點，兌換成功時，淨水管家即會通知服務專員於10日內與您聯繫。
    2.本項目於兌換後不可任意更換兌換內容物。
    3.本項目於點數扣除後無法復原。
    4.本項目僅適用於淨水管家使用，無法於實體通路進行兌換。
    5.相關櫻花點數商城辦法請至淨水管家的會員權益須知。
    6.兌換濾心不得轉售。`
  },
  31: {
    enable: true,
    name: 'P0583A-F0221 前置樹脂濾心',
    point: 790,
    info: `提供P0583A 所需的前置樹脂濾心，維持家中淨水器的飲用水水質健康。
    ※「P0583A 前置樹脂濾心」內容物包含：
    F0221 前置樹脂濾心，成份：無鈉型離子交換樹脂，共一支。`,
    notice: `1.本項目在確認兌換後，將扣除所需點數790點，兌換成功時，淨水管家即會通知服務專員於10日內與您聯繫。
    2.本項目於兌換後不可任意更換兌換內容物。
    3.本項目於點數扣除後無法復原。
    4.本項目僅適用於淨水管家使用，無法於實體通路進行兌換。
    5.相關櫻花點數商城辦法請至淨水管家的會員權益須知。
    6.兌換濾心不得轉售。`
  },
  32: {
    enable: true,
    name: 'P0583A-F0231 活性碳纖維濾心',
    point: 2690,
    info: `提供P0583A 所需的活性碳纖維濾心，維持家中淨水器的飲用水水質健康。
    ※「P0583A 活性碳纖維濾心」內容物包含：
    F0231 活性碳纖維濾心 (NSF認證)，成份：活性碳纖維，共一支。`,
    notice: `1.本項目在確認兌換後，將扣除所需點數2,690點，兌換成功時，淨水管家即會通知服務專員於10日內與您聯繫。
    2.本項目於兌換後不可任意更換兌換內容物。
    3.本項目於點數扣除後無法復原。
    4.本項目僅適用於淨水管家使用，無法於實體通路進行兌換。
    5.相關櫻花點數商城辦法請至淨水管家的會員權益須知。
    6.兌換濾心不得轉售。`
  }
}
