<template>
  <div
    v-show="isDialogShow"
    class="guide_dialog"
  >
    <el-dialog
      :visible.sync="isDialogShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :modal="false"
      :width="w"
    >
      <div class="content">
        <slot name="body" />
        <h1>注意事項</h1>
        <h2>{{ showExchangeText('name') }}</h2>
        <p v-html="showExchangeText('info')" />
        <h2>備註事項</h2>
        <p v-html="showExchangeText('notice')" />
        <div class="btnBox">
          <div
            v-if="isShowResolveClick"
            class="btn_1"
            @click="resolveClick"
          >
            <span>兌換</span>
          </div>
          <div
            class="btn_1"
            :style="{width: isShowResolveClick ? '' : '100%'}"
            @click="rejectClick"
          >
            <span>取消</span>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { tmpExchangeMap } from '../../tmp/tmp-exchange-map'
export default {
  name: 'TheQuerV2',
  props: {
    isDialogShow: { type: Boolean, default: false },
    isShowResolveClick: { type: Boolean, default: true },
    resolveClick: { type: Function, default: () => {} },
    rejectClick: { type: Function, default: () => {} },
    isShowClick: { type: Function, default: () => {} },
    w: { type: String, default: '35rem' },
    selectedId: { type: String, default: '' }
  },
  data () {
    return {
      exchangeMap: tmpExchangeMap
    }
  },
  mounted () {
    document.querySelector('#app .content').addEventListener('blur', () => document.querySelector('#app .content').focus())
  },
  methods: {
    showExchangeText (type) {
      if (this.selectedId) {
        return `${this.exchangeMap[this.selectedId][type] || ''}`.replace(/\n/g, '<br>')
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  max-height: calc(100vh * 0.5);
  overflow-y: auto;
}
.guide_dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: hidden;
  webkit-overflow: hidden;
  background-color:rgba(0, 0, 0, .6);
  /deep/ .el-dialog__wrapper{
  //  webkit-z-index: 9996!important;
  //  overflow: hidden;
  //  webkit-overflow: hidden;
  }
  /deep/ .el-dialog {
    // z-index: 9999!important;
    width: 35rem;
    border-radius: 1.4rem!important;
    /deep/ .el-dialog__body {
      padding: 0;
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 2.5rem;
      .imgs {
        display: flex;
        justify-content: center;
        width: 9rem;
        height: 8rem;
        img {
          height: 8rem;
        }
      }
      h1 {
        margin-top: 1.5rem;
        line-height: 3rem;
        font-size: 2.2rem;
        color: rgba(61, 61, 61, 1);
        font-weight: 700;
      }
      h2 {
        font-size: 18px;
        font-weight: 900;
        padding: 10px 0;
      }
      p {
        background-color: #fff1f1;
        padding: 10px;
        border-radius: 10px;
        color: #868686;
        width: 100%;
        margin: 5px 0 15px 0;
        line-height: 22px;
        font-size: 14px;
      }
      a {
        font-size: 1.2rem;
        line-height: 2rem;
        color: #c8161d;
      }
      .btnBox {
        display: flex;
        width: 100%;
        .btn_1 {
          display: flex;
          justify-content: center;
          align-items: center;
          width: calc(50% - 10px);
          margin: 0 5px;
          height: 4.6rem;
          margin-top: 4rem;
          background: #c8161d;
          border-radius: .6rem;
          font-size: 1.4rem;
          color: rgba(255, 255, 255, 1);
          cursor: pointer;
        }
        .btn_2 {
          margin-top: 1.2rem;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
