<template>
  <div
    v-show="isDialogShow"
    class="guide_dialog"
  >
    <el-dialog
      :visible.sync="isDialogShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :modal="false"
    >
      <div class="content">
        <slot name="body" />
        <h1>兌換資訊</h1>
        <br>
        <el-form
          ref="form"
          :model="form"
          label-width="9rem"
        >
          <el-row>
            <el-col :sm="24">
              <el-form-item label="收貨人姓名">
                <el-input
                  v-model="form.name"
                  :readonly="readonly"
                />
              </el-form-item>
            </el-col>
            <!-- <el-col :sm="24">
              <el-form-item label="收貨地址">
                <el-input
                  v-model="form.address"
                  type="textarea"
                  autosize
                  :readonly="readonly"
                />
              </el-form-item>
            </el-col> -->
          </el-row>
          <el-row>
            <el-col>
              <el-form-item
                label="縣市"
                class="addressCol"
              >
                <el-select
                  v-model="form.city"
                  placeholder="通訊地址-縣市"
                  @change="form.CustAddrCityId = '';form.CustAddrRoadId = '';form.CustAddrDetail= '';"
                >
                  <el-option
                    label="請選擇縣市"
                    value=""
                  >
                    請選擇縣市
                  </el-option>
                  <el-option
                    v-for="(item,index) in cityList"
                    :key="index"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                label="行政區"
                class="addressCol"
              >
                <el-select
                  v-model="form.CustAddrCityId"
                  placeholder="通訊地址-行政區"
                  @change="setZipRoadList();form.CustAddrRoadId = '';form.CustAddrDetail= '';"
                >
                  <el-option
                    v-for="(item,index) in showDistOptions()"
                    :key="index"
                    :label="item.zipname"
                    :value="item.hf_zipId"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item
                label="路段"
                class="road"
              >
                <el-select
                  v-model="form.CustAddrRoadId"
                  placeholder="路段"
                  @change="form.CustAddrDetail= '';"
                >
                  <el-option
                    v-for="(item,index) in zipRoadList"
                    :key="index"
                    :label="item.roadname"
                    :value="item.roadid"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="巷弄號碼樓層">
                <el-input
                  v-model="form.CustAddrDetail"
                  :readonly="readonly"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="24">
              <el-form-item label="手機">
                <el-input
                  v-model="form.phone"
                  :readonly="readonly"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="24">
              <el-form-item label="市話">
                <el-input
                  v-model="form.tel"
                  :readonly="readonly"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="24">
              <span class="myDatepickerTitle">預計收貨日期</span>
              <div class="myDatepickerBox">
                <myDatepicker
                  :date="form.startTime"
                  :option="option"
                  :limit="limit"
                />
              </div>
            </el-col>
          </el-row>
        </el-form>
        <div class="btnBox">
          <button
            v-if="!readonly"
            class="btn_1"
            :disabled="!checkFormVerify"
            @click="resolveClick"
          >
            <span>兌換</span>
          </button>
          <button
            class="btn_1"
            @click="rejectClick"
          >
            <span>{{ readonly ? '關閉' : '取消' }}</span>
          </button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as _ from 'lodash'
import myDatepicker from 'vue-datepicker'
import { getZipDist, getZipRoad, getCustomerByLineMid } from '../../api/api'
import { NewTimeAddDays } from '@/util'

export default {
  name: 'TheQuerV2Info',
  components: {
    myDatepicker
  },
  props: {
    isDialogShow: { type: Boolean, default: false },
    resolveClick: { type: Function, default: () => {} },
    rejectClick: { type: Function, default: () => {} },
    isShowClick: { type: Function, default: () => {} },
    selectedId: { type: String, default: '' },
    readonly: { type: Boolean, default: false },
    form: {
      type: Object,
      default: function () {
        return {
          name: '',
          address: '',
          phone: '',
          tel: '',
          startTime: {
            time: ''
          },
          city: '',
          CustAddrCityId: '',
          CustAddrRoadId: '',
          CustAddrDetail: ''
        }
      }
    }
  },
  data () {
    return {
      option: {
        type: 'day',
        week: ['週一', '週二', '週三', '週四', '週五', '週六', '週日'],
        month: [
          '1月',
          '2月',
          '3月',
          '4月',
          '5月',
          '6月',
          '7月',
          '8月',
          '9月',
          '10月',
          '11月',
          '12月'
        ],
        format: 'YYYY-MM-DD',
        inputStyle: {
          display: 'inline-block',
          'line-height': '22px',
          width: '100%',
          'font-size': '14px',
          border: 'none',
          outline: 'none',
          color: '#5F5F5F',
          margin: '0'
        },
        color: {
          // 字體顏色
          header: '#35acff', // 頭部
          headerText: '#fff' // 頭部文案
        },
        buttons: {
          cancel: '取消'
        },
        placeholder: '請選時間',
        dismissible: true
      },
      limit: [
        {
          type: 'weekday',
          available: [1, 2, 3, 4, 5, 6]
        },
        {
          type: 'fromto',
          from: NewTimeAddDays(10),
          to: NewTimeAddDays(44)
        }
      ],
      cityList: [
        '基隆市',
        '臺北市',
        '新北市',
        '桃園市',
        '新竹市',
        '新竹縣',
        '苗栗縣',
        '臺中市',
        '彰化縣',
        '南投縣',
        '雲林縣',
        '嘉義市',
        '嘉義縣',
        '臺南市',
        '高雄市',
        '屏東縣',
        '臺東縣',
        '花蓮縣',
        '宜蘭縣',
        '澎湖縣',
        '金門縣',
        '連江縣'
      ],
      zipDistList: [],
      zipRoadList: []
    }
  },
  computed: {
    checkFormVerify () {
      return (
        this.form.name &&
        this.form.address &&
        this.form.CustAddrDetail &&
        this.form.phone &&
        this.form.startTime.time
      )
    }
  },
  watch: {
    form: {
      handler: 'combineRoad',
      deep: true
    },
    readonly: function (newEl, oldEl) {
      if (newEl) {
        this.limit = [
          {
            type: 'weekday',
            available: [1, 2, 3, 4, 5, 6]
          },
          {
            type: 'fromto',
            from: this.form.startTime.time,
            to: this.form.startTime.time
          }
        ]
      } else {
        this.limit = [
          {
            type: 'weekday',
            available: [1, 2, 3, 4, 5, 6]
          },
          {
            type: 'fromto',
            from: NewTimeAddDays(10),
            to: NewTimeAddDays(44)
          }
        ]
      }
    }
  },
  async mounted () {
    const LINEMid = this.getUserLineId(this.$route) || ''

    document.querySelector('#app .content').addEventListener('blur', () => document.querySelector('#app .content').focus())

    this.zipDistList = await this.getDistList()
    const res = _.get(await getCustomerByLineMid({ LINEMid }), 'data.Data')
    if (res) {
      const targetCity = `${res.CustAddress.split('-')[1].split(/縣|市/)[0].replace('台', '臺')}${/市/.test(res.CustAddress) ? '市' : '縣'}`
      this.form.city = targetCity
      this.form.CustAddrCityId = res.AddrCityId
      this.form.CustAddrRoadId = res.AddrRoadId
      this.form.CustAddrDetail = res.AddrDetail
      await this.setZipRoadList()

      const roadID = this.form.CustAddrRoadId
      // 在詳細的地址只取路段
      const index = _.findIndex(this.zipRoadList, function (e) { return e.roadid === roadID })
      const ArrayVaule = this.form.CustAddrDetail.split(this.zipRoadList[index].roadname)
      this.form.CustAddrDetail = ArrayVaule[ArrayVaule.length - 1]
    }
  },
  methods: {
    async getDistList () {
      const res = await getZipDist()
      const zipDistList = _.get(res, 'data.Data')
      return zipDistList
    },
    async getZipRoadList (ZipNo) {
      const res = await getZipRoad({ ZipNo })
      const zipRoadList = _.get(res, 'data.Data')
      return zipRoadList
    },
    showDistOptions () {
      return this.zipDistList.filter(o => this.form.city && o.zipname.includes(this.form.city.replace(/臺/, '台'))).map(o => ({ ...o, zipname: `${o.zipno} ${o.zipname.split('-')[1].split(/市|縣/)[1]}` }))
    },
    async setZipRoadList () {
      if (this.form.CustAddrCityId) {
        const hfZipId = this.form.CustAddrCityId
        const selectedDist = this.zipDistList.find(o => o.hf_zipId === hfZipId)
        const zip = selectedDist.zipno
        const list = await this.getZipRoadList(zip)
        this.zipRoadList = list
      }
    },
    combineRoad () {
      // 合併所有地址資訊並合成完整地址
      const hfZipId = this.form.CustAddrCityId
      const selectedDist = this.zipDistList.find(o => o.hf_zipId === hfZipId)
      const zipname = selectedDist.zipname
      let Roadname = ''
      const RoadId = this.form.CustAddrRoadId
      if (this.zipRoadList.length > 0) {
        const selectedRoad = this.zipRoadList.find(o => o.roadid === RoadId)
        Roadname = selectedRoad.roadname
      }
      this.form.address = `${zipname}${Roadname}${this.form.CustAddrDetail}`
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  max-height: calc(100vh - 220px);
  overflow-y: auto;
}
.myDatepickerBox {
  display: inline-block;
  border: solid 1px #dcdfe5;
  border-radius: 3px;
  padding: 7px;
}
span.myDatepickerTitle {
  margin-right: 15px;
}
.el-form-item__label {
  font-size: 14px;
}
.guide_dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: hidden;
  webkit-overflow: hidden;
  background-color: rgba(0, 0, 0, .6);
  /deep/ .el-dialog__wrapper {
    //  webkit-z-index: 9996!important;
    //  overflow: hidden;
    //  webkit-overflow: hidden;
  }
  /deep/ .el-dialog {
    // z-index: 9999!important;
    width: 35rem;
    border-radius: 1.4rem !important;
    /deep/ .el-dialog__body {
      padding: 0;
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 2.5rem;
      .imgs {
        display: flex;
        justify-content: center;
        width: 9rem;
        height: 8rem;
        img {
          height: 8rem;
        }
      }
      h1 {
        margin-top: 1.5rem;
        line-height: 3rem;
        font-size: 2.2rem;
        color: rgba(61, 61, 61, 1);
        font-weight: 700;
      }
      h2 {
        font-size: 18px;
        font-weight: 900;
        padding: 10px 0;
      }
      p {
        background-color: #e0f6fd;
        padding: 10px;
        border-radius: 10px;
        color: #868686;
        width: 100%;
        margin: 5px 0 15px 0;
        line-height: 22px;
        font-size: 14px;
      }
      a {
        font-size: 1.2rem;
        line-height: 2rem;
        color: #c8161d;
      }
      .btnBox {
        display: flex;
        width: 100%;
        justify-content: center;
        .btn_1 {
          display: flex;
          justify-content: center;
          align-items: center;
          width: calc(50% - 10px);
          margin: 0 5px;
          height: 4.6rem;
          margin-top: 4rem;
          background-color: #c8161d;
          border-radius: 0.6rem;
          font-size: 1.4rem;
          color: rgba(255, 255, 255, 1);
          cursor: pointer;
          border: 0;
        }
        .btn_1[disabled] {
          background-color: #c7c7c7;
        }
        .btn_2 {
          margin-top: 1.2rem;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
