<template>
  <div class="contents">
    <div class="titleBox">
      <div class="zh">
        點數查詢
      </div>
      <div class="en">
        Point Inquiry
      </div>
    </div>
    <el-card class="mainBox">
      <el-row class="pointBox">
        <el-col
          :xs="12"
          :sm="12"
          :md="12"
          class="pointTitle"
        >
          剩餘點數
        </el-col>
        <el-col
          :xs="12"
          :sm="12"
          :md="12"
        >
          <div class="pointNum">
            {{ points }} 點
          </div>
        </el-col>
      </el-row>
      <el-row class="tabBox">
        <el-tabs
          v-model="tabActiveName"
          @tab-click="handleClick"
        >
          <el-tab-pane
            label="商品兌換"
            name="first"
          >
            <el-row
              v-for="(item, index) in couponList"
              :key="index"
            >
              <el-card class="box-card info-color">
                <el-col
                  :xs="4"
                  :sm="4"
                  :md="4"
                  class="imageCol"
                >
                  <div class="imageBorder">
                    <img
                      class="filter-img"
                      src="../assets/img/icon_list1.png"
                      alt=""
                    >
                  </div>
                </el-col>
                <el-col
                  :xs="16"
                  :sm="16"
                  :md="16"
                  class="infoCol"
                >
                  <div>{{ getProdName(item.id) }}機種</div>
                  <div>{{ exchangeMap[item.id].name }}</div>
                  <div
                    class="infoPoint"
                    @click="getPoints()"
                  >
                    櫻花 Point：{{ exchangeMap[item.id].point }} 點
                  </div>
                </el-col>
                <el-col
                  :xs="4"
                  :sm="4"
                  :md="4"
                  class="buttonCol"
                >
                  <button
                    type="button"
                    @click="_btnStartExchange(item.id)"
                  >
                    查看
                  </button>
                </el-col>
              </el-card>
            </el-row>
          </el-tab-pane>
          <el-tab-pane
            label="歷史紀錄"
            name="second"
          >
            <el-row
              v-for="(item, index) in historyList"
              :key="index"
            >
              <el-card
                v-if="item.type === 2"
                class="box-card info-color"
              >
                <el-col
                  :xs="4"
                  :sm="4"
                  :md="4"
                  class="imageCol"
                >
                  <div class="imageBorder">
                    <img
                      class="filter-img"
                      src="../assets/img/icon_list1.png"
                      alt=""
                    >
                  </div>
                </el-col>
                <el-col
                  :xs="16"
                  :sm="16"
                  :md="16"
                  class="infoCol"
                >
                  <div>{{ getProdName(item.prize_redemption_items) }}機種</div>
                  <div>{{ exchangeMap[item.prize_redemption_items].name }}</div>
                  <div class="infoPoint">
                    櫻花 Point：{{ item.mypoint }} 點
                  </div>
                </el-col>
                <el-col
                  :xs="4"
                  :sm="4"
                  :md="4"
                  class="buttonCol"
                >
                  <button
                    type="button"
                    @click="getFromData(item.id)"
                  >
                    查看
                  </button>
                </el-col>
              </el-card>
              <el-card
                v-else-if="item.type === 3"
                class="box-card warning-color"
              >
                <el-col
                  :xs="4"
                  :sm="4"
                  :md="4"
                  class="imageCol"
                >
                  <div class="imageBorder">
                    <img
                      class="image-gift"
                      src="../assets/icon/point.png"
                      alt=""
                    >
                  </div>
                </el-col>
                <el-col
                  :xs="16"
                  :sm="16"
                  :md="16"
                  class="infoCol"
                >
                  <ul>
                    <li>手動調整點數</li>
                    <li class="infoPoint">
                      櫻花 Point：+{{ item.mypoint }} 點
                    </li>
                    <li>
                      備註：{{ item.note }}
                    </li>
                    <li>
                      調整時間：{{ getCreateDate(item.create_time) }}
                    </li>
                  </ul>
                </el-col>
              </el-card>
              <el-card
                v-else-if="item.type === 4"
                class="box-card warning-color"
              >
                <el-col
                  :xs="4"
                  :sm="4"
                  :md="4"
                  class="imageCol"
                >
                  <div class="imageBorder">
                    <img
                      class="image-gift"
                      src="../assets/icon/expire.png"
                      alt=""
                    >
                  </div>
                </el-col>
                <el-col
                  :xs="16"
                  :sm="16"
                  :md="16"
                  class="infoCol"
                >
                  <ul>
                    <li>點數到期</li>
                    <li class="infoPoint">
                      櫻花 Point：{{ item.mypoint }} 點
                    </li>
                    <li>
                      扣除日：{{ item.create_time }}
                    </li>
                  </ul>
                </el-col>
              </el-card>
              <el-card
                v-else
                class="box-card warning-color"
              >
                <el-col
                  :xs="4"
                  :sm="4"
                  :md="4"
                  class="imageCol"
                >
                  <div class="imageBorder">
                    <img
                      class="image-gift"
                      src="../assets/icon/gift.png"
                      alt=""
                    >
                  </div>
                </el-col>
                <el-col
                  :xs="16"
                  :sm="16"
                  :md="16"
                  class="infoCol"
                >
                  <ul>
                    <li>邀請好友獲得櫻花Point</li>
                    <li class="infoPoint">
                      櫻花 Point：+{{ item.mypoint }} 點
                    </li>
                    <li>到期日：{{ item.point_validity_date }}</li>
                    <li>邀請好友：{{ item.inviter_name }}</li>
                  </ul>
                </el-col>
              </el-card>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </el-row>
    </el-card>
    <guide-theQuerV2
      :is-dialog-show="isShowGuideClickExchange || isShowGuideHistoryExchange"
      :is-show-resolve-click="!isShowGuideHistoryExchange"
      :resolve-click="isGuideClickExchangeClick"
      :reject-click="
        () => {
          isShowGuideClickExchange = isShowGuideHistoryExchange = false
          scrollOpen()
        }
      "
      :selected-id="selectedId"
    />
    <guide-theQuerV2Info
      :is-dialog-show="isShowGuideInfoExchange"
      :resolve-click="isGuideInfoExchangeClick"
      :reject-click="
        () => {
          isShowGuideInfoExchange = false
          scrollOpen()
        }
      "
      :selected-id="selectedId"
      :form="form"
      :readonly="isGuideInfoReadonly"
    />
    <guide-theQuerV2Complete
      :is-dialog-show="isShowGuideCompleteExchange"
      :resolve-click="isGuideCompleteExchangeClick"
    />
    <guide-theQuerV2ShowInfo
      :form="formInfo"
      :is-dialog-show="isShowGuideShowInfo"
      @closeQuerV2ShowInfo="closeQuerV2ShowInfo"
    />
  </div>
</template>

<script>
import * as _ from 'lodash'
import * as moment from 'moment-timezone'
import { getCustomerByLineMid } from '../api/api'
import GuideTheQuerV2 from '@/components/PopUp/Guide_theQuerV2'
import GuideTheQuerV2Complete from '@/components/PopUp/Guide_theQuerV2_Complete'
import GuideTheQuerV2Info from '@/components/PopUp/Guide_theQuerV2_Info'
import GuideTheQuerV2ShowInfo from '@/components/PopUp/Guide_theQuerV2_ShowInfo'
import storage from '../storage'
import firebase from '../plugins/firebase'
import { tmpExchangeMap } from '../tmp/tmp-exchange-map'
import { MessageBox } from 'element-ui'
import { Holiday } from '@/util/holiday'
import axios from 'axios'

export default {
  components: {
    GuideTheQuerV2,
    GuideTheQuerV2Complete,
    GuideTheQuerV2Info,
    GuideTheQuerV2ShowInfo
  },
  data () {
    return {
      exchangeMap: tmpExchangeMap,
      LINEMid: '',
      num: 0,
      points: 0,
      isShowReplace: false,
      userName: {},
      isShowGuideClickExchange: false,
      isShowGuideHistoryExchange: false,
      isShowGuideInfoExchange: false,
      isShowGuideCompleteExchange: false,
      isGuideInfoReadonly: false,
      isShowGuideShowInfo: false,
      couponList: this.getCouponList(),
      historyList: [],
      selectedId: '',
      tabActiveName: 'first',
      form: {
        name: '',
        address: '',
        phone: '',
        tel: '',
        startTime: {
          time: ''
        },
        city: '',
        CustAddrCityId: '',
        CustAddrRoadId: '',
        CustAddrDetail: ''
      },
      formInfo: {
        name: '',
        address: '',
        phone: '',
        tel: '',
        startTime: {
          time: ''
        }
      }
    }
  },
  async mounted () {
    await this.liffInit(process.env.VUE_APP_LIFFID_POINT_QUERY)
    this.userName = storage.getItem('userName')
    this.LINEMid = this.getUserLineId(this.$route)
    await this.initMemberData()
    await this.countExchangeHistory()
  },
  methods: {
    getCreateDate (date) {
      return date.split('T')[0]
    },
    getProdName (id) {
      const num = tmpExchangeMap[id].name.indexOf('-')
      return tmpExchangeMap[id].name.substring(0, num)
    },
    getCouponList () {
      const couponList = []
      _.forIn(tmpExchangeMap, (v, k) => {
        if (tmpExchangeMap[k].enable === true) {
          couponList.push({ id: k })
        }
      })
      return couponList
    },

    async initMemberData () {
      const memberData = _.get(
        await getCustomerByLineMid({ LINEMid: this.LINEMid }),
        'data.Data'
      )
      this.form.name = _.get(memberData, 'CustName') || ''
      this.form.phone = _.get(memberData, 'Mobile') || ''
      this.form.address = _.get(memberData, 'CustAddress') || ''
      this.form.tel = _.get(memberData, 'tel') || ''
      this.form.startTime.time = moment()
        .add(this.getAddDay(11), 'days')
        .format('YYYY-MM-DD')
      this.points = await this.getPoints()
    },

    getAddDay (iDay) {
      let result = 0
      const Today = moment()
      const whichweek = Today.isoWeekday()

      // 判斷是否為星期三
      // 星期三的話因為加11天後就星期日 所以要再加一天
      if (whichweek === 3) {
        result = iDay + 1
      } else {
        result = iDay
      }
      return result
    },

    async getFromData (id) {
      const idString = id.toString()
      const docRef = firebase.firestore
        .collection('exchangeFilterElement')
        .doc(idString)
      docRef.get().then(doc => {
        if (doc.exists) {
          this.formInfo.name = _.get(doc.data(), 'name') || ''
          this.formInfo.phone = _.get(doc.data(), 'phone') || ''
          this.formInfo.address = _.get(doc.data(), 'address') || ''
          this.formInfo.tel = _.get(doc.data(), 'tel') || ''
          const date = _.get(doc.data(), 'reservationDate').toDate()
          this.formInfo.startTime.time = moment(date).format('YYYY-MM-DD') || ''
        }
        this.isShowGuideShowInfo = true
      })
      // .catch(error => {
      //   console.error('Error getting document:', error)
      // })
    },

    isGuideClickExchangeClick () {
      this.isShowGuideClickExchange = false
      this.isGuideInfoReadonly = false
      this.isShowGuideInfoExchange = true
    },
    isGuideCompleteExchangeClick () {
      this.isShowGuideCompleteExchange = false
      this.scrollOpen()
    },
    async isGuideInfoExchangeClick () {
      this.isGuideInfoReadonly = true
      const dateString = moment(this.form.startTime.time).format('YYYYMMDD')
      // 檢查國定假日
      if (Holiday.holidayList.includes(dateString)) {
        this.isShowGuideInfoExchange = false
        MessageBox.alert('您選的日期為國定假日', '提示', {
          confirmButtonText: '確定',
          type: 'warning',
          callback: action => {
            this.isShowGuideInfoExchange = true
            this.isGuideInfoReadonly = false
          },
          confirmButtonClass: 'custom-btn-confirm'
        })
        return
      }
      // 檢查日期（因套件有bug）
      if (!this.checkDateStriing(dateString)) {
        this.isShowGuideInfoExchange = false
        MessageBox.alert('您選的日期必須是10天後至60天內', '提示', {
          confirmButtonText: '確定',
          type: 'warning',
          callback: action => {
            this.isShowGuideInfoExchange = true
            this.isGuideInfoReadonly = false
          },
          confirmButtonClass: 'custom-btn-confirm'
        })
        return
      }
      const dateSteing = moment
        .tz(this.form.startTime.time, 'Asia/Taipei')
        .format('YYYY/MM/DD') // 預計收貨日期
      const res = await this.createrOrder(
        this.LINEMid,
        this.selectedId,
        this.form.name,
        this.form.address,
        this.form.tel,
        this.form.phone,
        dateSteing,
        this.form.CustAddrCityId,
        this.form.CustAddrRoadId,
        this.form.CustAddrDetail
      )
      if (!res.id) {
        MessageBox.alert('表單提交失敗, 如有疑問請聯繫櫻花客服人員', '提示', {
          confirmButtonText: '確定',
          type: 'warning',
          confirmButtonClass: 'custom-btn-confirm'
        })
      }
      this.isShowGuideInfoExchange = false
      this.isShowGuideCompleteExchange = true
      this.isGuideInfoReadonly = false
      this.points = await this.getPoints()
      this.scrollOpen()
    },
    async _btnStartExchange (id) {
      this.points = await this.getPoints()
      if (this.points < this.exchangeMap[id].point) {
        MessageBox.alert('您的點數不足', '提示', {
          confirmButtonText: '確定',
          type: 'warning',
          confirmButtonClass: 'custom-btn-confirm'
        })
        return
      }
      this.selectedId = id
      await this.initMemberData()
      this.isShowGuideClickExchange = true
      this.scrollStop()
    },
    _btnViewExchange (id) {
      this.isShowGuideHistoryExchange = true
      this.selectedId = id
    },
    showExchangeInfo (id) {
      // console.log('tmpExchangeMap => ', tmpExchangeMap)
    },
    async countExchangeHistory () {
      this.historyList = await this.getPointHistory(this.LINEMid)
    },
    async handleClick (event) {
      this.countExchangeHistory()
      this.points = await this.getPoints()
    },
    async getPoints () {
      const res = await axios.get(
        `https://sakura-line-dialogflow-dnz3lqp74q-de.a.run.app/kelake/point/${this.LINEMid}`
      )
      return res.data.mypoint || 0
    },
    async getPointHistory () {
      const res = await axios.get(
        `https://sakura-line-dialogflow-dnz3lqp74q-de.a.run.app/kelake/pointhistory/${this.LINEMid}`
      )
      return res.data.sort((a, b) => b.id - a.id)
    },
    async createrOrder (
      lineUid,
      selectedId,
      receiverName,
      receiverAddress,
      tel,
      mobile,
      dateString,
      CustAddrCityId,
      CustAddrRoadId,
      CustAddrDetail
    ) {
      const url = `https://sakura-line-dialogflow-dnz3lqp74q-de.a.run.app/kelake/pointhistory/${lineUid}`
      const body = {
        prize_redemption_items: selectedId,
        receiverName: receiverName,
        receiverAddress: receiverAddress,
        tel: tel,
        mobile: mobile,
        dateString: dateString,
        CustAddrCityId: CustAddrCityId,
        CustAddrRoadId: CustAddrRoadId,
        CustAddrDetail: CustAddrDetail
      }
      const res = await axios.post(url, body)
      return res.data
    },
    scrollStop () {
      document.querySelector('#app .content').style.setProperty('overflow', 'hidden')
      document.querySelector('#app .content').style.setProperty('position', 'fixed')
    },
    scrollOpen () {
      document.querySelector('#app .content').style.setProperty('overflow', '')
      document.querySelector('#app .content').style.setProperty('position', 'relative')
    },
    closeQuerV2ShowInfo () {
      this.isShowGuideShowInfo = false
    },
    checkDateStriing (dateString) {
      const diffDays = moment(dateString).diff(moment(), 'days')
      if (diffDays < 10 || diffDays > 60) {
        return false
      } else {
        return true
      }
    }
  }
}
</script>
<style>
.el-message-box {
  width: 80% !important;
}

.custom-btn-confirm {
  background-color: #c8161d !important;
  border-color: #c8161d !important;
}
</style>
<style scoped lang="scss">
.titleBox {
  text-align: center;
  color: #131313;
}
.titleBox .zh {
  font-size: 31px;
  padding: 5px 0;
}
.titleBox .en {
  font-size: 12px;
  padding: 0 0 30px 0;
  color: #BDBDBD;
  line-height: 1.7rem;
  font-weight: 200;
}
.card {
  border-radius: 1rem;
  padding: 2rem 3rem;
  margin-bottom: 2rem;
}
.pointBox {
  font-size: 18px;
  text-align: center;
}
.pointNum {
  background-color: #fff1f1;
  border: solid #c8161d 2px;
  border-radius: 5px;
  padding: 7px 0;
  color: #c8161d;
  width: 80%;
}
.pointTitle {
  padding-top: 10px;
  font-weight: 500;
}
.el-tabs {
  .el-row {
    // border-bottom: solid #888888 1px;
    // padding-bottom: 11px;
    margin-bottom: 10px;
  }

}

/deep/ .tabBox {
  min-height: 150px;
  .el-tabs__nav {
    width: 100%;
    .el-tabs__item {
      text-align: center;
      width: 50%;
    }
    .el-tabs__item.is-active {
      color: #c8161d;
    }
    .el-tabs__active-bar {
      background-color: #c8161d !important;
    }
    .el-tabs__item:hover{
      color: #c8161d;
    }
  }
}

.buttonCol {
  padding-top: 10px;
  button {
    background-color: #c8161d;
    border: 0;
    border-radius: 5px;
    padding: 10px 15px;
    color: white;
  }
}

.imageCol {
  text-align: center;
  padding: 5px 0 0 0;
  .imageBorder {
    width: 60px;
    margin: auto;
    padding: 5px;
    background-color: white;
    border-radius: 10px;
    .filter-img {
      width: 18px;
    }
  }
}

.image-gift {
  width: 90%;
}

.infoCol {
  font-size: 14px;
  line-height: 20px;
  color: #43494d;
  padding: 0px 10px;
  .infoPoint {
    color: #c8161d;
  }
}

.box-card {
  border-radius: 1rem;
  padding-bottom: 16px;
}
.info-color {
  background-color: #fff1f1;
}
.warning-color {
  background-color: #fde2e2;
}

@import '../assets/styles/mixin.scss';
.contents {
  position: relative;
  @media screen and (max-width: 992px) {
    .pointTitle {
      padding-top: 10px;
    }
    .imageCol {
      .imageBorder {
        width: auto;
      }
    }
  }
}

</style>
